import * as React from "react"
import PropTypes from "prop-types"
import Header from "../Header/index"
import Footer from "../Footer/index"
import "./styles.scss"

const Layout = ({ children }) => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="main">
          <main>{children}</main>
        </div>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
