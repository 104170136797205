import * as React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const Header = ({ siteTitle }) => {
  return (
    <div className="header" />
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
